import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "mr-ui-kit/dist/mr-uikit.css";
import MrUiKit from "mr-ui-kit";
import "bootstrap";

import "@/plugins/axios";

createApp(App).use(store).use(router).use(MrUiKit).mount("#app");
