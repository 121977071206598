import { GeneralFile } from ".";

export interface ContentText {
  content: string;
  id: number;
  type: string;
}

export enum DocumentType {
  LINK = "link",
  FILE = "file",
}

export interface Document {
  type: DocumentType.FILE | DocumentType.LINK;
  objectTypes: any;
  name: string;
  link: string;
  fileId?: number;
  filename?: string;
  file?: GeneralFile | null;
  dealer: boolean;
  noDealer: boolean;
  id?: number;
  lastModified?: any;
  lastModifiedDate?: null;
  size?: 0;
  webkitRelativePath?: "";
}

export enum HomeObjectType {
  Link = "link",
  File = "file",
}
