export interface Credentials {
  email: string;
  password?: string;
}

export interface ResetPassword {
  password: string;
  confirmPassword: string;
}

export interface Tokens {
  token: string;
  refreshToken: string;
  user: User;
}

export interface VerifyEmail {
  email: string;
  token: string;
}

export interface FirstResetPasswordBody {
  token: string;
  password: string;
}

export interface User {
  email: string | null;
  id: number | null;
  role: string | null;
  isAdmin: boolean | null;
  registeredName: string | null;
}

export enum UserRole {
  Admininistrator = "admin",
  Dealer = "dealer",
  NoDealer = "no-dealer",
}
