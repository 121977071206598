export interface RequestDocument {
  downloaded: boolean;
  downloadedAt: string | null;
  generatedAt: string | null;
  type: RequestDocumentType;
  url: string;
}

export enum RequestDocumentType {
  CONTRATTO = "contract",
  FOGLIO_INFORMATIVO = "information-sheet",
  PRIVACY = "privacy",
  CRIF = "crif",
  ADEGUATA_VERIFICA = "verification-doc",
}
