import {
  Credentials,
  Tokens,
  LocalStorageKeys,
  VerifyEmail,
  UserRole,
} from "@/interfaces";
import router from "@/router";
import store from "@/store";
import axios from "axios";

class AuthService {
  getToken(): string | null {
    return localStorage.getItem(LocalStorageKeys.TOKEN_KEY);
  }

  getRefreshToken(): string | null {
    return localStorage.getItem(LocalStorageKeys.REFRESH_KEY);
  }

  setToken(token: string): void {
    localStorage.setItem(LocalStorageKeys.TOKEN_KEY, token);
  }

  setRefreshToken(token: string): void {
    localStorage.setItem(LocalStorageKeys.REFRESH_KEY, token);
  }

  isLoggedIn(): boolean {
    return this.getToken() != null;
  }

  resetTokens(): void {
    localStorage.removeItem(LocalStorageKeys.TOKEN_KEY);
    localStorage.removeItem(LocalStorageKeys.REFRESH_KEY);
  }

  logout(): void {
    this.resetTokens();
    router.push("/login");
    // store.commit('setLayoutMode', LayoutMode.None);
  }

  async refreshToken(): Promise<Tokens> {
    const refreshToken = this.getRefreshToken();
    if (refreshToken) {
      try {
        const response = await axios.post<Tokens>("/users/refresh-token", {
          refreshToken,
        });
        const { data } = response;
        this.setToken(data.token);
        this.setRefreshToken(data.refreshToken);
        return data;
      } catch (err: any) {
        throw new Error("Refresh token not found");
      }
    } else {
      throw new Error("Refresh token not found");
    }
  }

  async firstResetPassword(token: string, password: string): Promise<Tokens> {
    const response = await axios.post<Tokens>("users/reset-password/" + token, {
      password,
    });
    const { data } = response;
    return data;
  }

  async resetPassword(email: string): Promise<Tokens> {
    const response = await axios.post<Tokens>("users/reset-request", { email });
    const { data } = response;
    return data;
  }

  async login(creds: Credentials): Promise<Tokens> {
    const response = await axios.post<Tokens>("/users/login", creds);
    const { data } = response;
    this.setToken(data.token);
    this.setRefreshToken(data.refreshToken);
    console.log(data.user)
    store.commit("setUser", data.user);
    store.dispatch("setup");
    router.push("/");
    return data;
  }

  async verifyEmail(token: string): Promise<VerifyEmail> {
    const { data } = await axios.post<VerifyEmail>("/users/verify-email", {
      token,
    });
    return data;
  }
}
export default new AuthService();
