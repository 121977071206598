import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import { RouteMeta, UserRole } from "@/interfaces";
import AuthService from "@/services/AuthService";

const routes: Array<RouteRecordRaw> = [
  {
    path: "",
    name: "main",
    component: () => import("../components/layouts/CommonLayout.vue"),
    children: [
      {
        path: "",
        name: "home",
        component: () => import("../components/pages/Home.vue"),
      },
      {
        path: "/new-request",
        name: "new-request",
        component: () => import("../components/pages/NewRequest.vue"),
        meta: {
          roles: [UserRole.Dealer, UserRole.NoDealer],
        } as RouteMeta,
      },
      {
        path: "/dealers-list",
        name: "dealers-list",
        component: () =>
          import("../components/Backoffice/Dealers/DealersList.vue"),
        meta: {
          roles: [UserRole.Admininistrator],
        } as RouteMeta,
      },
      {
        path: "/requests-list",
        name: "requests-list",
        component: () => import("../components/pages/RequestsList.vue"),
      },
      {
        path: "/request-list/:id",
        name: "request",
        component: () => import("../components/pages/RequestPage.vue"),
      },
    ],
  },
  {
    path: "/login",
    name: "login",
    component: () => import("../components/pages/Login.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.name !== "login" && !AuthService.isLoggedIn()) next({ name: "login" });
  next();
});

export default router;
