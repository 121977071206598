import { RootState, SetupResponse } from "@/interfaces";
import store from "@/store";
import axios, { AxiosResponse } from "axios";

class SetupService {
  async setup(): Promise<RootState> {
    const { data }: AxiosResponse<RootState> = await axios.get("/setup");
    store.commit("setUser", data.user);
    store.commit("setContent", data.content);
    store.commit("setObjects", data.objects);
    store.commit("setCountries", data.countries);
    store.commit("setUserRequestDocumentType", data.userRequestDocumentType);
    store.commit("setDocumentEntity", data.documentEntity);
    store.commit("setTypePolitical", data.typePolitical);
    store.commit("setRolePolitical", data.rolePolitical);
    store.commit("setDeadlineInstallment", data.deadlineInstallment);
    store.commit("setDebitAccount", data.debitAccount);
    store.commit("setHouseType", data.houseType);
    store.commit("setCustomerFamilyType", data.customerFamilyType);
    store.commit("setFundsSource", data.fundsSource);
    store.commit("setSponsorType", data.sponsorType);
    store.commit("setUserRequestStatus", data.userRequestStatus);
    return data;
  }
}

export default new SetupService();
