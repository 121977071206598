
import { defineComponent, onMounted, ref } from "vue";
import { useStore } from "vuex";
import AuthService from "./services/AuthService";

export default defineComponent({
  name: "App",
  setup() {
    const store = useStore();
    onMounted(async () => {
      if (AuthService.isLoggedIn()) {
        await store.dispatch("setup");
      }
    });
    return {};
  },
});
