import { ActionTree, createStore, GetterTree, MutationTree } from "vuex";
import SetupService from "@/services/SetupService";
import {
  AlertCfg,
  RequestResponse,
  RootState,
  SetupResponse,
  User,
  UserRequest,
} from "@/interfaces";

const state = {
  user: {
    id: null,
    email: null,
    isAdmin: null,
    role: "",
    registeredName: "",
  },
  content: "",
  objects: [],
  countries: [],
  customerFamilyType: [],
  deadlineInstallment: [],
  debitAccount: [],
  documentEntity: [],
  fundsSource: [],
  houseType: [],
  rolePolitical: [],
  typePolitical: [],
  userRequestDocumentType: [],
  userRequestStatus: [],
  sponsorType: [],
  request: {
    id: null,
    customerName: null,
    service: null,
    user: null,
    date: null,
    total: null,
    status: null,
    coe: null,
    email: null,
  },
  alert: {
    toggle: false,
    message: null,
    type: null,
  },
  requestToggle: {
      toggle: false
  },
};

const actions: ActionTree<RootState, RootState> = {
  async setup() {
    await SetupService.setup();
  },
};

const getters: GetterTree<RootState, RootState> = {
  getUser(state: RootState): User {
    return state.user;
  },
  getContent(state: SetupResponse) {
    return state.content;
  },
  getObjects(state: SetupResponse) {
    return state.objects;
  },
  getRequest(state: RootState) {
    return state.request;
  },
  getCountries(state: RootState) {
    return state.countries;
  },
  getUserRequestDocumentType(state: RootState) {
    return state.userRequestDocumentType;
  },
  getDocumentEntity(state: RootState) {
    return state.documentEntity;
  },
  getRolePolitical(state: RootState) {
    return state.rolePolitical;
  },
  getTypePolitical(state: RootState) {
    return state.typePolitical;
  },
  getDeadlineInstallment(state: RootState) {
    return state.deadlineInstallment;
  },
  getDebitAccount(state: RootState) {
    return state.debitAccount;
  },
  getHouseType(state: RootState) {
    return state.houseType;
  },
  getCustomerFamilyType(state: RootState) {
    return state.customerFamilyType;
  },
  getFundsSource(state: RootState) {
    return state.fundsSource;
  },
  getSponsorType(state: RootState) {
    return state.sponsorType;
  },
  getUserRequestStatus(state: RootState) {
    return state.userRequestStatus;
  },
  alertCfg(state: RootState): AlertCfg {
    return state.alert;
  },
  requestToggle(state: RootState) {
    return state.requestToggle;
  },
};

const mutations: MutationTree<RootState> = {
  setUser(state: RootState, user: User) {
    state.user = user;
  },
  setContent(state: SetupResponse, content: string) {
    state.content = content;
  },
  setObjects(state: SetupResponse, objects: any) {
    state.objects = objects;
  },
  setRequest(state: RootState, objects: RequestResponse) {
    state.request = objects;
  },
  setCountries(state: RootState, objects: any) {
    state.countries = objects;
  },
  setUserRequestDocumentType(state: RootState, objects: any) {
    state.userRequestDocumentType = objects;
  },
  setDocumentEntity(state: RootState, objects: any) {
    state.documentEntity = objects;
  },
  setTypePolitical(state: RootState, objects: any) {
    state.typePolitical = objects;
  },
  setRolePolitical(state: RootState, objects: any) {
    state.rolePolitical = objects;
  },
  setDeadlineInstallment(state: RootState, objects: any) {
    state.deadlineInstallment = objects;
  },
  setDebitAccount(state: RootState, objects: any) {
    state.debitAccount = objects;
  },
  setHouseType(state: RootState, objects: any) {
    state.houseType = objects;
  },
  setCustomerFamilyType(state: RootState, objects: any) {
    state.customerFamilyType = objects;
  },
  setFundsSource(state: RootState, objects: any) {
    state.fundsSource = objects;
  },
  setSponsorType(state: RootState, objects: any) {
    state.sponsorType = objects;
  },
  setUserRequestStatus(state: RootState, objects: any) {
    state.userRequestStatus = objects;
  },
  setRequestFromStep(state: RootState, objects: UserRequest) {
    state.request = {
      id: objects.requestId,
      customerName: null,
      service:
        objects.form && objects.form.loanInfo && objects.form.loanInfo.service
          ? objects.form.loanInfo.service
          : null,
      user: objects.user,
      date: null,
      total: null,
      status: objects.history ? objects.history.status : null,
      coe: null,
      email: objects.email,
    };
  },
  setAlert(state: RootState, cfg: AlertCfg) {
    state.alert = Object.assign(state.alert, {
      ...cfg,
      toggle: cfg.message != null,
    });
  },
  setRequestToggle(state: RootState) {
      state.requestToggle.toggle = !state.requestToggle.toggle
  }
};

export default createStore({
  state,
  mutations,
  actions,
  getters,
});
