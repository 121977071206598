export interface RequestResponse {
  id: number | null;
  customerName: string | null;
  service: string | null;
  user: string | null;
  date: string | null;
  total: string | null;
  status: UserRequestStatus | null;
  coe: string | null;
  email: string | null;
}
export interface UserRequest {
  requestId: number | null;
  isLocked: boolean | null;
  currentStep: number | null;
  allowedStep: number | null;
  form: FormInterface | null;
  history: History | null;
  user: string | null;
  email: string | null;
  status: any | null;
}

export interface History {
  date: string | null;
  message: string | null;
  status: UserRequestStatus | null;
}
export interface FormInterface {
  customer?: Customer;
  verification?: Verification | null;
  loanInfo?: LoanInfo;
  customerInfo?: CustomerInfo;
  otherInfo?: OtherInfo;
  doc?: Doc;
}
export interface Customer {
  name: string;
  lastname: string;
  dateOfBirth: string;
  birthCity: string;
  province: string;
  city: string;
  cap: string;
  address: string;
  houseNumber: string;
  sameResidenceAddress?: boolean | null;
  domicileAddress?: string;
  domicileHouseNumber?: string;
  domicileCity?: string;
  domicileProvince?: string;
  domicileCap?: string;
  domicileCountry: any;
  country: any;
  fiscalCode: string;
  telephone: string;
  email: string;
}

export interface Verification {
  mainActivity: string;
  countryMainActivity?: number | any;
  citizenship?: any;
  personalDocument?: PersonalDocument | null;
  // fundsOrigin: string,
  fundsSource: string | null;
  politicalPerson: boolean;
  typePolitical?: TypePolitical | null;
  rolePolitical?: RolePolitical | null;
  notes: string;
}

export interface PersonalDocument {
  type?: UserRequestDocumentType | any;
  number: string;
  releaseDate: string | null;
  country: any;
  releaseEntity?: DocumentEntity | any;
}

export interface LoanInfo {
  economicOperator: EconomicOperator;
  service: string;
  prices: Prices;
  firstInstallmentDeadlineType: DeadlineInstallment | null;
  // serviceOrigin: string,
  totalInstallment: string;
  tan: string;
  taeg: string;
  debitAccount: DebitAccount | null;
  iban: string;
  headerCc: string;
}
export interface EconomicOperator {
  name: string;
  address: string;
}
export interface Prices {
  total: string;
  financeCost: string;
  preliminaryCost: string;
  additionalCost: string;
  otherAdditionalCost: string;
  monthlyPayment: string;
}

export interface CustomerInfo {
  house: any;
  totalMonthlyHouseCost: string;
  familyType?: CustomerFamilyType | null;
  totalChildren?: number | null;
  otherBanks: string;
  notes: string | null,
  fundsSource?: string | null;
  personalWorks: string | null;
  employeeWorks: string | null;
  otherWorks: string | null;
  monthlySalary: string;
  otherFinancial: boolean;
  costs: Costs[];
  identityCardCustomer?: number;
  paycheckCustomer?: number;
}
export interface Costs {
  debit: string;
  totalMonthly: string;
  deadline: any;
}

export interface OtherInfo {
  sponsor: Sponsor;
  notes: string;
  identityCardSponsor: any;
  paycheckSponsor: any;
}

export interface Sponsor extends CustomerInfo {
  type: any;
  name: string;
  lastname: string;
}

export interface HistoryMessage {
  status: string;
  message: string;
  date: string;
}

export interface Doc {
  contract: number;
  "information-sheet": number;
  privacy: number;
  crif: number;
  "verification-doc": number;
}
export interface BreadcrumbItem {
  label: string;
  path: string;
}

export enum SponsorType {
  Sponsor = "sponsor",
  Spouse = "spouse",
  Engaged = "engaged",
  NoSponsor = "no-sponsor",
}

export enum UserRequestDocumentType {
  IdentityCard = "identity-card",
  Passport = "passport",
  DrivingLicense = "driving-license",
  Visa = "visa",
}

export enum DocumentEntity {
  Municipality = "municipality",
  Prefecture = "prefecture",
  MaritalStatus = "marital",
  ForeignOffice = "foreign",
  VehicleOffice = "vehicle",
  Motorization = "motorization",
  Other = "other",
}

export enum DeadlineInstallment {
  After15 = "after-15",
  EndOfMonth = "end-of-month",
}

export enum DebitAccount {
  FromOrigin = "origin",
  Other = "other",
}

export enum HouseType {
  Personal = "personal",
  Rent = "rent",
  Other = "other",
}

export enum CustomerFamilyType {
  Single = "single",
  Divorced = "divorced",
  Married = "married",
}

export enum UserRequestStatus {
  Draft = "draft", // Only no-admin user
  WaitingReview = "waiting-review", // from dealer
  RequestOperation = "request-operation", // operation from admin
  Rejected = "rejected", // only-view
  Accepted = "accepted", // upload signed doc
}

export enum TypePolitical {
  President = "president",
  HeadGovernment = "head-government",
  Minister = "ministro",
  DeputyMinister = "deputy-minister",
  Undersecretary = "undersecretary",
  Parliamentary = "parliamentary",
  MemberSupremeCourt = "member-supreme-court",
  MemberConstitutionalCourt = "member-constitutional-court",
  OtherJudicial = "other-judicial",
  MemberAuditors = "member-auditors",
  DirectorCentralBank = "director-central-bank",
  Ambassador = "ambassador",
  AIF = "aif",
  ArmedForcesOfficer = "armed-forces-officer",
  Parent = "parent",
  Son = "son",
  Judge3 = "judge-3",
  JudgeExtraordinary = "judge-extraordinary",
  CloseTies = "close-ties",
  RegentCaptain = "regent-captain",
  StateSecretary = "state-secretary",
  GGCouncilMember = "gg-council-member",
  LawCommissioner = "law-commissioner",
  JudgeAppellate = "judge-appellate",
  CollegeMember = "college-member",
  VigilanceBankMember = "vigilance-bank-member",
  HighOfficialPolice = "high-official-police",
  HighOfficialPolitical = "high-official-political",
  Spouse = "spouse",
  JudgeCivil = "jugdge-civil",
  RSM = "rsm",
  MemberPublicEntities = "member-public-entities",
  TrustMember = "trust-member",
  MemberAdministration = "member-administration",
  TaxAttorney = "tax-attorney",
  Other = "other",
}

export enum RolePolitical {
  LocalAdministration = "local-administration",
  StateAdministration = "state-administration",
  Organization = "organization",
  Association = "association",
  Foundation = "foundation",
  PublicEntities = "public-entities",
  Other = "other",
}

export enum FundsSource {
  PersonalWorks = "personalWorks",
  EmployeeWorks = "employeeWorks",
  Pension = "pension",
  Other = "other",
}
